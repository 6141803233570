/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {
  getRouteParams,
  getAdvancedRulesetDisplay,
  getAppGroupLabelTypes,
  getRoutePreviousParams,
} from 'containers/App/AppState';
import {getAllUsersMap, getUserSelectorHistory, isUserScoped} from 'containers/User/UserState';
import {getDisplayNames} from 'containers/Label/LabelSettings/LabelSettingState';
import {isScopeRequired} from '../List/RulesetListState';
import {rulesGridSettings, ipTablesGridSettings} from './Configs/RulesetItemConfig';
import {fillPolicyObjUserInfo, getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';
import {hrefUtils} from '@illumio-shared/utils';
import {
  areActionButtonsDisabled,
  getPolicyGeneratorId,
  getRulesetSummaryObj,
  shouldShowOverrideDenyVenVersionWarning,
} from './RulesetItemUtils';
import {moveItemInArray} from '@illumio-shared/utils/intl/utils';
import {produce} from 'immer';
import {isOnboardingObject} from 'antman/utils/general';

export default {
  detail(state = {}, action) {
    switch (action.type) {
      case 'RULESET_GET_ITEM':
        return action.data;
      case 'RULESET_ITEM_REORDER':
        return produce(state, ({active, draft}) => {
          const {from, to, pversion, type} = action.data;
          const key = type === 'overrideDeny' || type === 'deny' ? 'deny_rules' : 'rules';
          const pversionObj = pversion === 'draft' || (draft && !draft.update_type) ? draft : active;

          // Deny rules and override deny rules are displayed in separate grids but they are stored together
          // To reorder rules, we need to adjust the index to the already existing rules from the opposite category
          const {fromInc, toInc} = pversionObj[key].reduce(
            ({fromInc, toInc}, item, index) => {
              // Count how many rules of the opposite category currently precedes the rule we are reordering
              if ((type === 'overrideDeny' || type === 'deny') && item.override ^ (type === 'overrideDeny')) {
                return {
                  fromInc: index - fromInc <= from ? fromInc + 1 : fromInc,
                  toInc: index - toInc <= to ? toInc + 1 : toInc,
                };
              }

              return {fromInc, toInc};
            },
            {fromInc: 0, toInc: 0},
          );

          // Adjust the index before applying the reorder
          pversionObj[key] = moveItemInArray(pversionObj[key], from + fromInc, to + toInc);
        });
      default:
        return state;
    }
  },
  pceUpgradeRuleset(state = {}, action) {
    switch (action.type) {
      case 'RULESET_GET_PCE_UPGRADE_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getRulesetDetail = state => state.ruleset.detail;
export const getPCEUpgradeRuleset = state => state.ruleset.pceUpgradeRuleset;

export const getRulesetVersions = createSelector([getRulesetDetail, getRouteParams], (rulesetDetail, {pversion}) =>
  getPolicyVersions(rulesetDetail, pversion),
);

export const getRulesetItem = createSelector(
  [
    ipTablesGridSettings,
    rulesGridSettings,
    getRulesetVersions,
    getRouteParams,
    getAllUsersMap,
    getAdvancedRulesetDisplay,
    isUserScoped,
    isScopeRequired,
    getAppGroupLabelTypes,
    getRoutePreviousParams,
    getRulesetDetail,
    getUserSelectorHistory,
    getDisplayNames,
  ],
  (
    ipTablesGridSettings,
    rulesGridSettings,
    {versions, pversionObjIsDeleted, isOldVersion},
    {pversion, tab},
    usersMap,
    advancedRulesetDisplay,
    userIsScoped,
    scopeIsRequired,
    appGroupsType,
    prevRouteParams,
    {oldVens},
  ) => {
    const provisionIsDisabled = !versions.draft?.caps.includes('provision');
    const rulesetId = hrefUtils.getId(versions.pversionObj?.href);

    const versionsWithUserInfo = fillPolicyObjUserInfo(versions, usersMap);

    const policyGeneratorId = getPolicyGeneratorId(versions.pversionObj?.scopes[0], appGroupsType);
    const actionButtonsDisabled = areActionButtonsDisabled(versions);
    const showOverrideDenyVenVersionWarning = shouldShowOverrideDenyVenVersionWarning(
      versions.pversionObj,
      oldVens?.count ?? 0,
    );

    return {
      ipTablesGridSettings,
      rulesGridSettings,
      versions: versionsWithUserInfo,
      pversionObjIsDeleted,
      pversion,
      tab,
      provisionIsDisabled,
      rulesetId,
      isOldVersion,
      summaryObj: getRulesetSummaryObj(versionsWithUserInfo),
      isAdditionPending: versions.draft?.update_type === 'create',
      advancedRulesetDisplay,
      userIsScoped,
      scopeIsRequired,
      policyGeneratorId,
      prevRouteParams,
      actionButtonsDisabled,
      showOverrideDenyVenVersionWarning,
      oldVenVersion: oldVens?.version ?? '',
      isXpressOnboardingObject: __ANTMAN__ && isOnboardingObject(versions.pversionObj?.external_data_reference),
    };
  },
);
